const FieldsMessages = {
  password: {
    insertYourPassword: 'Por favor, insira sua senha',
    invalidPassword: 'A senha deve ter no mínimo 6 digitos',
  },
};

export const fieldPasswordRules = () => [
  {
    required: true,
    message: FieldsMessages.password.insertYourPassword,
  },
  {
    min: 6,
    message: FieldsMessages.password.invalidPassword,
  },
];
