"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.httpCodes = void 0;
const responseCodes = {
    success: {
        general: {},
        user: {
            userLoggedInSuccessfully: 2000,
            userLoggedOutSuccessfully: 2001,
            userRegisteredSuccessfully: 2002,
            passwordResetMailSentSuccessfully: 2003,
            userPasswordUpdatedSuccessfully: 2004,
            resendEmailConfirmationSuccessfully: 2005,
            userProfileFoundSuccessfully: 2006,
            editProfileEditedSuccessfullyWithEmailChange: 2007,
            userProfileEditedSuccessfullyWithoutEmailChange: 2008,
            userEmailValidatedSuccessfully: 2009,
            passwordRedefinedSuccessfully: 2010,
            userDeletedSuccessfully: 2011,
            userViewedSuccessfully: 2012,
            usersListedSuccessfully: 2013,
            userCreatedSuccessfully: 2014,
            userUpdatedSuccessfully: 2015,
        },
        room: {
            listedAvailableRooms: 7000,
            showedRoom: 7001,
            listedNotAvailableRooms: 7002,
            availableRoomDetails: 7003,
            listedAllRooms: 7004,
        },
        property: {
            listedProperties: 8000,
            updatedBookingPageMultiplierProperties: 8001,
        },
        testimonial: {
            listAllTestimonials: 9001,
        },
        payment: {
            listedInstallments: 10000,
        },
        authorization: {
            authorizationCreate: 11000,
        },
        paymentSession: {
            paymentSessionCreated: 12000,
        },
        checkout: {
            checkoutCreated: 13000,
        },
        transactionSearch: {
            transactionSearch: 14000,
        },
        invoiceBeds: {
            invoiceCreated: 15000,
        },
        bookingBeds: {
            bookingBedsCreated: 16000,
            bookingBedsModified: 16001,
            bookingBedsCancelled: 16002,
        },
        bookingsBeds: {
            bookingBedsRetrieved: 17000,
        },
        bookings: {
            listAllBookings: 18000,
            bookingRetrieved: 18001,
            bookingSynchronized: 18002,
        },
        owners: {
            createOwner: 19000,
            listAllOwners: 19001,
            getOwnerByID: 19002,
            updateOwner: 19003,
            deleteOwner: 19004,
            listAllFurnishedOwners: 19005,
        },
        roomInventory: {
            roomInventorySynchronized: 20000,
        },
        coupon: {
            couponApplied: 21000,
        },
        bookingInCart: {
            tokenValid: 22000,
        },
    },
    error: {
        general: {
            somethingUnexpectedHappened: -1000,
            unauthorized: -1001,
            forbidden: -1002,
            invalidParameters: -1003,
            notImplemented: -1004,
            validationFails: -1005,
        },
        user: {
            invalidEmailOrPassword: -2000,
            emailNotConfirmed: -2001,
            userNotActivated: -2002,
            emailAlreadyInUse: -2003,
            userNotFound: -2004,
            invalidOldPassword: -2005,
            userWithConfirmedEmail: -2006,
            emailTokenNotFound: -2007,
            invalidEmailToken: -2008,
            passwordResetTokenNotFound: -2009,
            facebookAuthenticationError: -2010,
            facebookCommunicationError: -2011,
            googleAuthenticationError: -2013,
            googleCommunicationError: -2014,
        },
        room: {
            roomNotFound: -7000,
            roomFromAfterTo: -7001,
            roomDateOutOfRange: -7002,
            roomDateGenericError: -7003,
            roomDatesAreTheSame: -7004,
        },
        testimonial: {
            CannotCreateTestimonial: -9000,
            CannotGetTestimonialByID: -9001,
            CannotUpdateTestimonial: -9002,
        },
        payment: {
            PaymentNotFound: -10000,
        },
        transaction: {
            transactionNotFound: -11000,
        },
        itemRequest: {
            validationFailed: -12000,
        },
        senderRequest: {
            documentInvalid: -13000,
        },
        bookingBeds: {
            bookingFailed: -14000,
            bookingNotFound: -14001,
            bookingUpdateFailed: -14002,
        },
        bookingsBeds: {
            bookingsCantRetrieve: -15000,
            bookingsCantSynchronize: -15001,
        },
        bookings: {
            bookingNotFound: -16000,
        },
        owners: {
            cannotCreateOwner: -17000,
            cannotGetOwnerByID: -17001,
            cannotUpdateOwner: -17002,
        },
        property: {
            cannotUpdatePropertyBookingPageMultiplier: -18000,
        },
        roomInventory: {
            cannotSynchronizeRoomInventory: -19000,
        },
        coupon: {
            cannotApplyCoupon: -20000,
            typeCouponNotExists: -21000,
        },
        bookingInCart: {
            invalidLink: -22000,
            unavailableBooking: -22001,
        },
    },
};
// TODO remover o uso do status do http e utilizar apenas os códigos da resposta
exports.httpCodes = {
    success: {
        ok: 200,
    },
    clientError: {
        unauthorized: 401,
        notFound: 404,
    },
};
exports.default = responseCodes;
