"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_ROOMS_PER_CHECKOUT = exports.MAX_GUESTS_PER_ROOM = exports.DEFAULT_GUESTS_PER_ROOM = exports.HUBSPOT_PORTAL = exports.TIME_ZONE_DEFAULT = exports.BOOKING_TIME_CHECKIN_LIMIT = exports.ERRO_CEP = exports.DEFAULT_FORMAT_DATE_INTERNATIONAL = exports.CURRENT_VERSION = exports.PAYMENT_ERROR = exports.LOCAL_STORAGE = exports.PARKING_FEATURE = exports.SCREEN_WIDTH = exports.OWNER_INFORMATIONS = exports.CLIENT_INFORMATIONS = exports.CEP_HYPHEN_REGEX = exports.REMOVE_SPACES_REGEX = exports.CEP_REGEX = exports.CARD_NUMBER_REGEX = exports.SECONDS_LIMIT_FORMAT = exports.SECONDS_IN_ONE_MINUTE = exports.PERSONAL_DOC_REGEX = exports.PHONE_REGEX = exports.MIN_EMAIL_LENGTH = exports.MAX_CEP_LENGTH = exports.BRAND_IMAGE_LINK_68X30 = exports.CARD_NUMBER_MAX_LENGTH = exports.CARD_NUMBER_MIN_LENGTH = exports.DEFAULT_FORMAT_DATE_PT_BR = exports.DEFAULT_VALUES_BEDS24_BOOKING = exports.DEFAULT_VALUES_BEDS24_INVOICE = exports.DEFAULT_MSG_BEDS24_INFO_ITEMS = exports.BEDS24_MAX_FIELD_LENGTH = exports.DEFAULT_NUMCHILD_BEDS24 = exports.DEFAULT_CLEANING_FEE = exports.STATUS_REQUEST_BEDS24 = exports.DEFAULT_SUITE_QTY = exports.REACT_APP_BLIP_CHAT_KEY = exports.PAGSEGURO_LINK = exports.CODE_DDI_BRAZIL = exports.PAYMENT_TYPE = exports.STATES = exports.VERSION = exports.ENCRYPTION_KEY = exports.DEFAULT_LANGUAGE = exports.FACEBOOK_ID = exports.GOOGLE_ID = exports.PORTAL_FRONT_URL = exports.PORTAL_API_URL = exports.API_URL = void 0;
exports.GOOGLE_ANALYTICS_TAG_CONVERSION = exports.GOOGLE_ANALYTICS_UA = exports.MAX_ROOMS_PER_CHECKOUT = void 0;
const { API_URL, CURRENT_VERSION, PORTAL_API_URL, PORTAL_FRONT_URL } = process.env;
exports.API_URL = API_URL;
exports.CURRENT_VERSION = CURRENT_VERSION;
exports.PORTAL_API_URL = PORTAL_API_URL;
exports.PORTAL_FRONT_URL = PORTAL_FRONT_URL;
const GOOGLE_ID = process.env.GOOGLE_ID || '90607171413-3l0cs5j8e06ecu64ogsh9b5kha8lctje.apps.googleusercontent.com';
exports.GOOGLE_ID = GOOGLE_ID;
const FACEBOOK_ID = process.env.FACEBOOK_ID || '2727270204171139';
exports.FACEBOOK_ID = FACEBOOK_ID;
const REACT_APP_BLIP_CHAT_KEY = 'Ym90d2hhdHNhcHA3OmI0NTVjZDU2LWE5MzYtNGQ4My1iMTQ5LWVjOTUwNDljZTZhNQ==';
exports.REACT_APP_BLIP_CHAT_KEY = REACT_APP_BLIP_CHAT_KEY;
const DEFAULT_LANGUAGE = 'PT';
exports.DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;
const DEFAULT_SUITE_QTY = '01';
exports.DEFAULT_SUITE_QTY = DEFAULT_SUITE_QTY;
const STATUS_REQUEST_BEDS24 = 3;
exports.STATUS_REQUEST_BEDS24 = STATUS_REQUEST_BEDS24;
const DEFAULT_NUMCHILD_BEDS24 = '0';
exports.DEFAULT_NUMCHILD_BEDS24 = DEFAULT_NUMCHILD_BEDS24;
const DEFAULT_CLEANING_FEE = 90;
exports.DEFAULT_CLEANING_FEE = DEFAULT_CLEANING_FEE;
const BEDS24_MAX_FIELD_LENGTH = 990;
exports.BEDS24_MAX_FIELD_LENGTH = BEDS24_MAX_FIELD_LENGTH;
// for browser storage encryption
const ENCRYPTION_KEY = 'uNDxaABR9TFJV4LzJEeKxRzhSVXt3qU7';
exports.ENCRYPTION_KEY = ENCRYPTION_KEY;
const VERSION = `${process.env.CURRENT_VERSION}-${process.env.BUILD_NUMBER}`;
exports.VERSION = VERSION;
const PARKING_FEATURE = 'PARKING_INCLUDED';
exports.PARKING_FEATURE = PARKING_FEATURE;
const STATES = [
    { initials: 'AC', state: 'Acre' },
    { initials: 'AL', state: 'Alagoas' },
    { initials: 'AP', state: 'Amapá' },
    { initials: 'AM', state: 'Amazonas' },
    { initials: 'BA', state: 'Bahia' },
    { initials: 'CE', state: 'Ceará' },
    { initials: 'DF', state: 'Distrito Federal' },
    { initials: 'ES', state: 'Espírito Santo' },
    { initials: 'GO', state: 'Goiás' },
    { initials: 'MA', state: 'Maranhão' },
    { initials: 'MT', state: 'Mato Grosso' },
    { initials: 'MS', state: 'Mato Grosso do Sul' },
    { initials: 'MG', state: 'Minas Gerais' },
    { initials: 'PA', state: 'Pará' },
    { initials: 'PB', state: 'Paraíba' },
    { initials: 'PR', state: 'Paraná' },
    { initials: 'PE', state: 'Pernambuco' },
    { initials: 'PI', state: 'Piauí' },
    { initials: 'RJ', state: 'Rio de Janeiro' },
    { initials: 'RN', state: 'Rio Grande do Norte' },
    { initials: 'RS', state: 'Rio Grande do Sul' },
    { initials: 'RO', state: 'Rondônia' },
    { initials: 'RR', state: 'Roraima' },
    { initials: 'SC', state: 'Santa Catarina' },
    { initials: 'SP', state: 'São Paulo' },
    { initials: 'SE', state: 'Sergipe' },
    { initials: 'TO', state: 'Tocantins' },
];
exports.STATES = STATES;
const PAYMENT_TYPE = {
    CREDIT: 'CREDIT_CARD',
    DEBIT: 'ONLINE_DEBIT',
    PIX: 'PIX',
};
exports.PAYMENT_TYPE = PAYMENT_TYPE;
const PAYMENT_ERROR = [
    {
        code: 53019,
        message: 'Ops, aconteceu um problema com seu pagamento. Por favor insira um telefone com um DDD válido.',
    },
    {
        code: 53021,
        message: 'Ops, aconteceu um problema com seu pagamento. Por favor insira um telefone válido.',
    },
    {
        code: 11013,
        message: 'Ops, aconteceu um problema com seu pagamento. Por favor insira um telefone com um DDD válido.',
    },
    {
        code: 11014,
        message: 'Ops, aconteceu um problema com seu pagamento. Por favor insira um telefone válido.',
    },
];
exports.PAYMENT_ERROR = PAYMENT_ERROR;
const CODE_DDI_BRAZIL = '55';
exports.CODE_DDI_BRAZIL = CODE_DDI_BRAZIL;
const DEFAULT_MSG_BEDS24_INFO_ITEMS = {
    CODE: 'PAGAMENTO',
    TEXT: 'PAGAMENTO PENDENTE PAGSEGURO - RESERVA 360 SUITES',
};
exports.DEFAULT_MSG_BEDS24_INFO_ITEMS = DEFAULT_MSG_BEDS24_INFO_ITEMS;
const DEFAULT_VALUES_BEDS24_INVOICE = {
    status: 'Pendente',
    qty: '1',
    vatRate: '0',
    type: '0',
    invoiceeId: '',
};
exports.DEFAULT_VALUES_BEDS24_INVOICE = DEFAULT_VALUES_BEDS24_INVOICE;
const DEFAULT_VALUES_BEDS24_BOOKING = {
    custom10: 'PagSeguro',
    refererEditable: 'site360suites',
};
exports.DEFAULT_VALUES_BEDS24_BOOKING = DEFAULT_VALUES_BEDS24_BOOKING;
const DEFAULT_FORMAT_DATE_PT_BR = 'DD/MM/YYYY';
exports.DEFAULT_FORMAT_DATE_PT_BR = DEFAULT_FORMAT_DATE_PT_BR;
const DEFAULT_FORMAT_DATE_INTERNATIONAL = 'YYYY-MM-DD';
exports.DEFAULT_FORMAT_DATE_INTERNATIONAL = DEFAULT_FORMAT_DATE_INTERNATIONAL;
const PHONE_REGEX = /\(|\)|_|-/g;
exports.PHONE_REGEX = PHONE_REGEX;
const PERSONAL_DOC_REGEX = /_|\.|\/|-/g;
exports.PERSONAL_DOC_REGEX = PERSONAL_DOC_REGEX;
const CARD_NUMBER_REGEX = /_| /g;
exports.CARD_NUMBER_REGEX = CARD_NUMBER_REGEX;
const CEP_REGEX = /_/g;
exports.CEP_REGEX = CEP_REGEX;
const CEP_HYPHEN_REGEX = /-/g;
exports.CEP_HYPHEN_REGEX = CEP_HYPHEN_REGEX;
const REMOVE_SPACES_REGEX = /\s/g;
exports.REMOVE_SPACES_REGEX = REMOVE_SPACES_REGEX;
const MIN_EMAIL_LENGTH = 5;
exports.MIN_EMAIL_LENGTH = MIN_EMAIL_LENGTH;
const MAX_CEP_LENGTH = 8;
exports.MAX_CEP_LENGTH = MAX_CEP_LENGTH;
const CARD_NUMBER_MIN_LENGTH = 6;
exports.CARD_NUMBER_MIN_LENGTH = CARD_NUMBER_MIN_LENGTH;
const CARD_NUMBER_MAX_LENGTH = 16;
exports.CARD_NUMBER_MAX_LENGTH = CARD_NUMBER_MAX_LENGTH;
const PAGSEGURO_LINK = 'https://stc.pagseguro.uol.com.br';
exports.PAGSEGURO_LINK = PAGSEGURO_LINK;
const BRAND_IMAGE_LINK_68X30 = 'https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/';
exports.BRAND_IMAGE_LINK_68X30 = BRAND_IMAGE_LINK_68X30;
const SECONDS_IN_ONE_MINUTE = 60;
exports.SECONDS_IN_ONE_MINUTE = SECONDS_IN_ONE_MINUTE;
const SECONDS_LIMIT_FORMAT = 10;
exports.SECONDS_LIMIT_FORMAT = SECONDS_LIMIT_FORMAT;
const CLIENT_INFORMATIONS = {
    email: 'contato@360suites.com.br',
    cell: '+55 (11) 97696-6234',
    cell_2: '+55 (11) 96171-8008',
    telephone: '+55 (11) 3136-1757',
    telephone_2: '+55 (11) 3180-3737',
    address: 'Av. Marquês de São Vicente',
    number: '121',
    neighborhood: 'Santa Cecilia',
    CEP: '01139-001',
    city: 'São Paulo',
    state: 'SP',
    CNPJ: '60.524.550/0001-31',
    LPemail: 'proprietario@360suites.com.br',
};
exports.CLIENT_INFORMATIONS = CLIENT_INFORMATIONS;
const OWNER_INFORMATIONS = {
    email: 'proprietario@360suites.com.br',
    cell: '+55 (11) 96171-8008',
    telephone: '+55 11 3136-1757',
    CEP: '01139-001',
    address: 'Av. Marquês de São Vicente, 121',
    city: 'Santa Cecília',
    state: 'São Paulo - SP',
};
exports.OWNER_INFORMATIONS = OWNER_INFORMATIONS;
const SCREEN_WIDTH = {
    smallLaptop: 993,
    laptop: 1200,
    tablet: 768,
    mobile: 510,
};
exports.SCREEN_WIDTH = SCREEN_WIDTH;
const LOCAL_STORAGE = {
    paymentInformation: 'XP360:paymentInformation',
    bookingId: 'XP360:bookingId',
};
exports.LOCAL_STORAGE = LOCAL_STORAGE;
const DEFAULT_GUESTS_PER_ROOM = 2;
exports.DEFAULT_GUESTS_PER_ROOM = DEFAULT_GUESTS_PER_ROOM;
const MAX_GUESTS_PER_ROOM = 5;
exports.MAX_GUESTS_PER_ROOM = MAX_GUESTS_PER_ROOM;
const DEFAULT_ROOMS_PER_CHECKOUT = 1;
exports.DEFAULT_ROOMS_PER_CHECKOUT = DEFAULT_ROOMS_PER_CHECKOUT;
const MAX_ROOMS_PER_CHECKOUT = 1;
exports.MAX_ROOMS_PER_CHECKOUT = MAX_ROOMS_PER_CHECKOUT;
const ERRO_CEP = {};
exports.ERRO_CEP = ERRO_CEP;
ERRO_CEP['CEP NAO ENCONTRADO'] = 'CEP não encontrado';
ERRO_CEP['Todos os serviços de CEP retornaram erro.'] = 'CEP inválido';
ERRO_CEP['Não foi possível interpretar o XML de resposta.'] = 'CEP inválido';
const BOOKING_TIME_CHECKIN_LIMIT = process.env.BOOKING_TIME_CHECKIN_LIMIT || '30 14 * * *';
exports.BOOKING_TIME_CHECKIN_LIMIT = BOOKING_TIME_CHECKIN_LIMIT;
const TIME_ZONE_DEFAULT = 'America/Sao_Paulo';
exports.TIME_ZONE_DEFAULT = TIME_ZONE_DEFAULT;
const HUBSPOT_PORTAL = process.env.HUBSPOT_PORTAL_ID;
exports.HUBSPOT_PORTAL = HUBSPOT_PORTAL;
const GOOGLE_ANALYTICS_UA = process.env.GOOGLE_ANALYTICS_ID;
exports.GOOGLE_ANALYTICS_UA = GOOGLE_ANALYTICS_UA;
const GOOGLE_ANALYTICS_TAG_CONVERSION = process.env.GOOGLE_ANALYTICS_CONVERSION;
exports.GOOGLE_ANALYTICS_TAG_CONVERSION = GOOGLE_ANALYTICS_TAG_CONVERSION;
