"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertIConfirmValuesToIPaymentInformation = void 0;
const convertIConfirmValuesToIPaymentInformation = (confirmValues, bookId) => ({
    initialDate: confirmValues.dates.initialDate,
    finalDate: confirmValues.dates.finalDate,
    firstNight: confirmValues.dates.firstNightString,
    lastNight: confirmValues.dates.lastNightString,
    dailyQtyString: confirmValues.dates.dailyQtyString,
    guestsQtyString: confirmValues.guestsQtyString,
    suitePrice: confirmValues.prices.suitePrice,
    cleaningFee: confirmValues.prices.cleaningFee,
    cleaningFeeFormatted: confirmValues.prices.cleaningFeeFormatted,
    serviceChargePrice: confirmValues.prices.serviceFee,
    serviceFeeFormatted: confirmValues.prices.serviceFeeFormatted,
    serviceChargePercentage: confirmValues.prices.serviceFeePercentage,
    totalNumber: confirmValues.prices.totalNumber,
    totalDecimal: confirmValues.prices.totalDecimal,
    suite: {
        propId: confirmValues.suite.propId,
        roomId: confirmValues.suite.roomId,
        suiteTitle: confirmValues.suite.suiteTitle,
        buildingName: confirmValues.suite.buildingName,
    },
    dailyPrice: confirmValues.prices.dailyPrice,
    quantity: confirmValues.quantity,
    guestsQty: confirmValues.guestsQty,
    dailyPriceValueFormatted: confirmValues.prices.dailyPriceValueFormatted,
    discountPercent: confirmValues.prices.discountPercent,
    dailyPriceGross: confirmValues.prices.dailyPriceGross,
    dailyPriceGrossFormatted: confirmValues.prices.dailyPriceGrossFormatted,
    originalTotalPrice: confirmValues.prices.totalPrice,
    totalPrice: confirmValues.prices.totalPrice,
    bookId,
    isFromBookingInChart: false,
    coupon: undefined,
});
exports.convertIConfirmValuesToIPaymentInformation = convertIConfirmValuesToIPaymentInformation;
