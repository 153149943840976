"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_router_1 = require("react-router");
const constants_1 = require("../../../../constants/constants");
const helpers_1 = require("../../../../utils/helpers");
const routesPaths_1 = require("../../../../routes/routesPaths");
const TimerLeft = ({ timeLeftServer, textInformation, startTimer }) => {
    const history = (0, react_router_1.useHistory)();
    const calculateTimeLeft = (actualTime) => {
        const minutesCalc = Math.floor(actualTime / constants_1.SECONDS_IN_ONE_MINUTE);
        const secondsCalc = actualTime - minutesCalc * constants_1.SECONDS_IN_ONE_MINUTE;
        let minutesFormatted = String(minutesCalc);
        let secondsFormatted = String(secondsCalc);
        if (minutesCalc < constants_1.SECONDS_LIMIT_FORMAT) {
            minutesFormatted = `0${minutesCalc}`;
        }
        if (secondsCalc < constants_1.SECONDS_LIMIT_FORMAT) {
            secondsFormatted = `0${secondsCalc}`;
        }
        // timeLeft
        return {
            actualLeft: actualTime,
            minutes: minutesCalc,
            seconds: secondsCalc,
            minutesFormatted,
            secondsFormatted,
        };
    };
    const [timeLeft, setTimeLeft] = (0, react_1.useState)(calculateTimeLeft(timeLeftServer));
    (0, react_1.useEffect)(() => {
        if (!startTimer)
            return;
        const timer = setTimeout(() => {
            const difference = timeLeft.actualLeft ? timeLeft.actualLeft - 1 : 0;
            setTimeLeft(calculateTimeLeft(difference));
        }, 1000);
        if (timeLeft.actualLeft === 0) {
            (0, helpers_1.handleCleanBookingStorage)();
            history.push({
                pathname: routesPaths_1.routesPaths.sessionExpired,
                state: { subTitle: 'Sua sessão expirou. Por favor, reserva sua suíte novamente.' },
            });
            return () => clearTimeout(timer);
        }
    }, [timeLeft, startTimer]);
    return (react_1.default.createElement("div", { style: { display: 'inline' } }, startTimer && (react_1.default.createElement("span", null, `${textInformation} ${timeLeft.minutesFormatted}:${timeLeft.secondsFormatted} `))));
};
exports.default = TimerLeft;
