import React from 'react';
import 'antd/dist/antd.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale-provider/pt_BR';
import './pages/global/global.scss';

import Routes from './routes/Routes';
import PropertiesProvider from './hooks/Properties';
// *** Não remover a importação, esta imagem é usada nos emails e precisa ser processada pelo webpack
import logoEmail from './assets/images/logo_360_oficial_email.png';

const App = () => (
  <PropertiesProvider>
    <ConfigProvider locale={ptBR}>
      <Routes />
    </ConfigProvider>
  </PropertiesProvider>
);

export default App;
