"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
require("./DocumentComponent.scss");
const suiteFormStyle_1 = require("../../suiteCollapse/suiteForm/suiteFormStyle");
const PaymentFormStyle_1 = require("../../../pages/paymentBooking/components/paymentForm/PaymentFormStyle");
const validations_1 = require("../../../utils/validations");
const format_1 = require("../../../utils/format");
const DocumentComponent = ({ form, documentTypeName, documentName, isRequired = true, }) => {
    const initialDocumentField = react_1.default.createElement(suiteFormStyle_1.MaskInputStyled, { type: "tel", mask: "111.111.111-11", placeholder: "XXX.XXX.XXX-XX" });
    const [documentField, setDocumentField] = (0, react_1.useState)(initialDocumentField);
    const updateDocumentFieldType = (0, react_1.useCallback)((documentTypeValue) => {
        switch (documentTypeValue) {
            case 'CPF':
                setDocumentField(react_1.default.createElement(suiteFormStyle_1.MaskInputStyled, { type: "tel", mask: "111.111.111-11", placeholder: "XXX.XXX.XXX-XX" }));
                break;
            case 'RG':
                setDocumentField(react_1.default.createElement(suiteFormStyle_1.MaskInputStyled, { mask: "11.111.111-*", placeholder: "XX.XXX.XXX-X" }));
                break;
            case 'PASSPORT':
                setDocumentField(react_1.default.createElement(suiteFormStyle_1.InputStyled, null));
                break;
            default:
                setDocumentField(initialDocumentField);
                break;
        }
    }, [form, setDocumentField]);
    const changeDocumentType = (0, react_1.useCallback)((documentType) => {
        if (documentType) {
            updateDocumentFieldType(documentType);
            form.setFieldsValue({ documentGuestRequired: '' });
        }
    }, [form]);
    (0, react_1.useEffect)(() => {
        const documentTypeValue = form.getFieldValue(documentTypeName);
        const documentValue = form.getFieldValue(documentName);
        if (documentTypeValue) {
            updateDocumentFieldType(documentTypeValue);
            form.setFieldsValue({ [documentName]: (0, format_1.getOnlyNumbers)(documentValue || '') });
        }
    }, [form.getFieldValue(documentTypeName)]);
    return (react_1.default.createElement("div", { className: "DocumentComponent" },
        react_1.default.createElement(antd_1.Row, { wrap: false },
            react_1.default.createElement(suiteFormStyle_1.FormItemFormularyRequired, { style: {
                    minWidth: '90px',
                    maxWidth: '90px',
                    marginRight: '5px',
                    display: 'block',
                }, labelCol: { span: 24 }, name: documentTypeName, label: "Tipo", help: false, initialValue: "CPF" },
                react_1.default.createElement(PaymentFormStyle_1.SelectStyled, { onChange: documentType => changeDocumentType(documentType), size: "large" },
                    react_1.default.createElement(PaymentFormStyle_1.SelectStyled.Option, { value: "CPF" }, "CPF"),
                    react_1.default.createElement(PaymentFormStyle_1.SelectStyled.Option, { value: "RG" }, "RG"),
                    react_1.default.createElement(PaymentFormStyle_1.SelectStyled.Option, { value: "PASSPORT" }, "PASS"))),
            react_1.default.createElement(antd_1.Col, { flex: "1" },
                react_1.default.createElement(suiteFormStyle_1.FormItemFormularyRequired, { labelCol: { span: 24 }, name: documentName, label: "Documento", validateTrigger: "onBlur", rules: [
                        {
                            required: isRequired,
                        },
                        () => ({
                            validator(rule, value) {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                const docType = form.getFieldValue('documentTypeGuestRequired');
                                if (docType === 'CPF') {
                                    if (!(0, validations_1.validateCPF)(value)) {
                                        return Promise.reject(new Error('Insira um CPF válido'));
                                    }
                                    return Promise.resolve();
                                }
                                return Promise.resolve();
                            },
                        }),
                    ], style: {
                        flex: '1',
                    } }, documentField)))));
};
exports.default = DocumentComponent;
