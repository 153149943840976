"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
require("./SearchFilterBar.scss");
const bs_1 = require("react-icons/bs");
const icons_1 = require("@ant-design/icons");
const options = [
    { label: 'Canceladas', value: 0 },
    { label: 'Pagamento Pendente', value: 3 },
    { label: 'Confirmadas', value: 1 },
    { label: 'Finalizadas', value: 4 },
];
const SearchBarFilter = ({ reservesListOriginal, updateReserveList, reservesListFiltered }) => {
    const { Search } = antd_1.Input;
    const { Panel } = antd_1.Collapse;
    const [filtersApplied, setFiltersApplied] = (0, react_1.useState)([]);
    const [wordSearch, setWordSearch] = (0, react_1.useState)('');
    const cleanFilter = () => {
        setFiltersApplied([]);
    };
    const handleReserveListFiltered = () => {
        const arrayTempFiltered = reservesListOriginal.filter(reserve => filtersApplied.includes(reserve.status));
        updateReserveList(arrayTempFiltered);
    };
    (0, react_1.useEffect)(() => {
        filtersApplied.length > 0 ? handleReserveListFiltered() : updateReserveList(reservesListOriginal);
        if (wordSearch.length > 0)
            searchReservesList(wordSearch);
    }, [filtersApplied]);
    const searchReservesList = (search) => {
        if (`${search}` !== '' || search !== null || search !== undefined || `${search}`.length > 0) {
            setWordSearch(search);
            const filteredData = reservesListOriginal.filter(entry => {
                const { bedsBookId, propertyName } = entry;
                return propertyName.toUpperCase().includes(search.toUpperCase()) || bedsBookId === search;
            });
            filtersApplied.length > 0
                ? updateReserveList(filteredData.filter(reserve => filtersApplied.includes(reserve.status)))
                : updateReserveList(filteredData);
        }
        else {
            setWordSearch('');
            handleReserveListFiltered();
        }
    };
    const onChange = (checkedValues) => {
        setFiltersApplied(checkedValues);
    };
    return (react_1.default.createElement("div", { className: "SearchFilterBar" },
        react_1.default.createElement(antd_1.Row, { className: "container" },
            react_1.default.createElement(Search, { enterButton: react_1.default.createElement(icons_1.SearchOutlined, null), placeholder: "Pesquisar", allowClear: true, onSearch: searchReservesList }),
            react_1.default.createElement(antd_1.Collapse, { expandIconPosition: "right", expandIcon: () => react_1.default.createElement(bs_1.BsCaretDownFill, null), bordered: true },
                react_1.default.createElement(Panel, { key: 1, header: react_1.default.createElement("div", { className: "property-string" }, `Filtros  ${filtersApplied.length > 0 ? `(${filtersApplied.length})` : ''}`) },
                    react_1.default.createElement(antd_1.Button, { className: "clean-filter", onClick: cleanFilter }, "Limpar filtro"),
                    react_1.default.createElement(antd_1.Checkbox.Group, { options: options, onChange: onChange, value: filtersApplied }))))));
};
exports.default = SearchBarFilter;
