"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatInfoPreCheckInBeds = void 0;
const constants_1 = require("../constants/constants");
const format_1 = require("./format");
const formatInfoPreCheckInBeds = (infoPreCheckIn) => {
    var _a, _b, _c, _d, _e, _f;
    const infoPreCheckInBeds = JSON.parse(infoPreCheckIn);
    if (JSON.stringify(infoPreCheckInBeds).length > constants_1.BEDS24_MAX_FIELD_LENGTH) {
        // remove o endereço
        delete infoPreCheckInBeds.address;
    }
    if (JSON.stringify(infoPreCheckInBeds).length > constants_1.BEDS24_MAX_FIELD_LENGTH) {
        // reduz para 2 nomes
        infoPreCheckInBeds.name = (0, format_1.onlyFirstAndLastName)(infoPreCheckInBeds.name);
        infoPreCheckInBeds.guests = (_a = infoPreCheckInBeds.guests) === null || _a === void 0 ? void 0 : _a.map((guest) => (Object.assign(Object.assign({}, guest), { name: (0, format_1.onlyFirstAndLastName)(guest.name) })));
        infoPreCheckInBeds.visitors = (_b = infoPreCheckInBeds.visitors) === null || _b === void 0 ? void 0 : _b.map((guest) => (Object.assign(Object.assign({}, guest), { name: (0, format_1.onlyFirstAndLastName)(guest.name) })));
    }
    if (JSON.stringify(infoPreCheckInBeds).length > constants_1.BEDS24_MAX_FIELD_LENGTH) {
        // remove o telefone (exceto do primeiro)
        infoPreCheckInBeds.guests = (_c = infoPreCheckInBeds.guests) === null || _c === void 0 ? void 0 : _c.map((guest) => {
            const newGuest = JSON.parse(JSON.stringify(guest));
            delete newGuest.phone;
            delete newGuest.ddi;
            return newGuest;
        });
        infoPreCheckInBeds.visitors = (_d = infoPreCheckInBeds.visitors) === null || _d === void 0 ? void 0 : _d.map((guest) => {
            const newGuest = JSON.parse(JSON.stringify(guest));
            delete newGuest.phone;
            delete newGuest.ddi;
            return newGuest;
        });
    }
    if (JSON.stringify(infoPreCheckInBeds).length > constants_1.BEDS24_MAX_FIELD_LENGTH) {
        // remove o email (exceto do primeiro)
        infoPreCheckInBeds.guests = (_e = infoPreCheckInBeds.guests) === null || _e === void 0 ? void 0 : _e.map((guest) => {
            const newGuest = JSON.parse(JSON.stringify(guest));
            delete newGuest.email;
            return newGuest;
        });
        infoPreCheckInBeds.visitors = (_f = infoPreCheckInBeds.visitors) === null || _f === void 0 ? void 0 : _f.map((guest) => {
            const newGuest = JSON.parse(JSON.stringify(guest));
            delete newGuest.email;
            return newGuest;
        });
    }
    return JSON.stringify(infoPreCheckInBeds);
};
exports.formatInfoPreCheckInBeds = formatInfoPreCheckInBeds;
