"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
require("./QuantityComponent.scss");
const QuantityComponent = ({ title, name, form, min, max, initialQty, updateText, onChange = () => { }, }) => {
    const [selectedQty, setSelectedQty] = (0, react_1.useState)(initialQty);
    (0, react_1.useEffect)(() => {
    }, [form.getFieldValue(name)]);
    return (react_1.default.createElement("div", { className: "QuantityComponent" },
        react_1.default.createElement("div", { className: "description" },
            react_1.default.createElement("div", { className: "title" }, title)),
        react_1.default.createElement("div", { className: "quantity" },
            react_1.default.createElement("div", { className: `add-remove no-select ${selectedQty <= min ? 'disable-color' : ''}`, onClick: () => {
                    if (selectedQty > min) {
                        const values = form.getFieldsValue();
                        const qty = values[name] - 1;
                        values[name] = qty;
                        setSelectedQty(qty);
                        form.setFieldsValue(values);
                        updateText();
                        onChange();
                    }
                } }, "-"),
            react_1.default.createElement("div", { className: "quantity-number" }, selectedQty),
            react_1.default.createElement("div", { className: `add-remove no-select ${selectedQty >= max ? 'disable-color' : ''}`, onClick: () => {
                    if (selectedQty < max) {
                        const values = form.getFieldsValue();
                        const qty = values[name] + 1;
                        values[name] = qty;
                        setSelectedQty(qty);
                        form.setFieldsValue(values);
                        updateText();
                        onChange();
                    }
                } }, "+"))));
};
exports.default = QuantityComponent;
