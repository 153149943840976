"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = __importStar(require("../api"));
const bookingService = {
    // POST /bookings/request
    createBooking: (bookingRequest) => __awaiter(void 0, void 0, void 0, function* () {
        (0, api_1.setAuthorization)();
        const response = yield api_1.default.post(`/bookings/request`, bookingRequest);
        return response.data;
    }),
    // PUT /bookings/booking
    updateBooking: (bookingRequest) => __awaiter(void 0, void 0, void 0, function* () {
        (0, api_1.setAuthorization)();
        const response = yield api_1.default.put(`/bookings/booking`, bookingRequest);
        return response.data;
    }),
    // GET /bookings/timeLeft/{id}
    getTimeLeft: (bookingId, isFromBookingInChart) => __awaiter(void 0, void 0, void 0, function* () {
        const isFromBookingInChartString = isFromBookingInChart ? '?isFromBookingInChart=true' : '';
        const response = yield api_1.default.get(`/bookings/timeLeft/${bookingId}${isFromBookingInChartString}`);
        return response.data;
    }),
    // TODO BACKEND esta chamada só está sendo usada para pegar o statusPayment do booking, porém está trazendo todas as informações do booking.
    // Converter essa chamada para algo especifico do statusPayment
    // GET /bookings/{id}
    getBookingStatusPayment: (bookingId) => __awaiter(void 0, void 0, void 0, function* () {
        (0, api_1.setAuthorization)();
        const response = yield api_1.default.get(`/bookings/${bookingId}`);
        return response.data;
    }),
    // TODO Verificar se essa chamada é realmente necessária, se a edição com o cupom pode ser feita em outro momento
    // TODO BACKEND Nenhum dos valores da resposta está sendo utilizado, poderia usar apenas a resposta padrão IDefaultResponseAPI
    // PUT /bookings/coupon
    calcCoupon: (params) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield api_1.default.put(`/bookings/coupon`, params);
        return response.data;
    }),
    // TODO BACKEND Chamda sem uso
    // GET /bookings/listAll
    // GET /bookings/history
    getMyBookings: () => __awaiter(void 0, void 0, void 0, function* () {
        (0, api_1.setAuthorization)();
        const response = yield api_1.default.get(`/bookings/history`);
        return response.data;
    }),
    // TODO BACKEND A resposta possui dados não usados, refatorar no backend
    // GET /bookings/listByUserId
    getCurrentReserve: () => __awaiter(void 0, void 0, void 0, function* () {
        (0, api_1.setAuthorization)();
        const response = yield api_1.default.get(`/bookings/listByUserId`);
        return response.data;
    }),
    // TODO BACKEND A resposta possui dados não usados, refatorar no backend
    // GET /bookings/listByPropNameAndUnitName
    getReserveInfoByIdAndName: (unitName, propName) => __awaiter(void 0, void 0, void 0, function* () {
        (0, api_1.setAuthorization)();
        const response = yield api_1.default.get(`/bookings/listByPropNameAndUnitName?unitName=${unitName}&propName=${propName}`);
        return response.data;
    }),
};
exports.default = bookingService;
