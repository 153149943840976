"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNumeric = exports.validateURL = exports.maxCharacters = exports.validateCNPJ = exports.validateCPF = void 0;
const validateCPF = (value) => {
    // Only numbers
    const strCPF = value.replace(/\D/g, '');
    if (strCPF.length > 11) {
        return false;
    }
    let Soma = 0;
    let Resto;
    if (strCPF === '00000000000')
        return false;
    if (strCPF === '11111111111')
        return false;
    if (strCPF === '22222222222')
        return false;
    if (strCPF === '33333333333')
        return false;
    if (strCPF === '44444444444')
        return false;
    if (strCPF === '55555555555')
        return false;
    if (strCPF === '66666666666')
        return false;
    if (strCPF === '77777777777')
        return false;
    if (strCPF === '88888888888')
        return false;
    if (strCPF === '99999999999')
        return false;
    for (let i = 1; i <= 9; i += 1)
        Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
    Resto = (Soma * 10) % 11;
    if (Resto === 10 || Resto === 11)
        Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10), 10))
        return false;
    Soma = 0;
    for (let i = 1; i <= 10; i += 1)
        Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
    Resto = (Soma * 10) % 11;
    if (Resto === 10 || Resto === 11)
        Resto = 0;
    return !(Resto !== parseInt(strCPF.substring(10, 11), 10));
};
exports.validateCPF = validateCPF;
const validateCNPJ = (value) => {
    if (!value)
        return false;
    // Aceita receber o valor como string, número ou array com todos os dígitos
    const validTypes = typeof value === 'string' || Number.isInteger(value) || Array.isArray(value);
    // Elimina valor em formato inválido
    if (!validTypes)
        return false;
    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];
    // Valida a quantidade de dígitos
    if (numbers.length !== 14)
        return false;
    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)];
    if (items.length === 1)
        return false;
    // Cálculo validador
    const calc = (x) => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;
        for (let i = x; i >= 1; i--) {
            const n = slice[x - i];
            sum += n * factor--;
            if (factor < 2)
                factor = 9;
        }
        const result = 11 - (sum % 11);
        return result > 9 ? 0 : result;
    };
    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12);
    // Valida 1o. dígito verificador
    const digit0 = calc(12);
    if (digit0 !== digits[0])
        return false;
    // Valida 2o. dígito verificador
    const digit1 = calc(13);
    return digit1 === digits[1];
};
exports.validateCNPJ = validateCNPJ;
const maxCharacters = (str, max) => {
    if (str.length > max) {
        return str.substring(0, max);
    }
    return str;
};
exports.maxCharacters = maxCharacters;
const validateURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
};
exports.validateURL = validateURL;
const isNumeric = (str) => {
    if (typeof str !== 'string')
        return false; // we only process strings!
    return (!Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !Number.isNaN(parseFloat(str))); // ...and ensure strings of whitespace fail
};
exports.isNumeric = isNumeric;
