"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const constants_1 = require("../constants/constants");
const useDeviceDetect = (maxWidth = constants_1.SCREEN_WIDTH.smallLaptop) => {
    const [isMobile, setMobile] = (0, react_1.useState)(true);
    const [isDesktop, setDesktop] = (0, react_1.useState)(true);
    const memoWidthWrapper = (0, react_1.useCallback)(() => {
        setMobile(window.innerWidth <= maxWidth);
        setDesktop(window.innerWidth > maxWidth);
    }, []);
    (0, react_1.useEffect)(() => {
        setMobile(window.innerWidth <= maxWidth);
        setDesktop(window.innerWidth > maxWidth);
        window.addEventListener('resize', memoWidthWrapper);
        return () => {
            window.removeEventListener('resize', memoWidthWrapper);
        };
    }, [maxWidth, memoWidthWrapper]);
    return { isMobile, isDesktop, width: window.innerWidth };
};
exports.default = useDeviceDetect;
