import { PORTAL_FRONT_URL } from '../constants/constants';

const routesPaths = {
  root: '/',
  login: '/entrar',
  register: '/registrar',
  forgotPassword: '/recuperar-senha',
  emailConfirmation: '/confirmar-email',
  resetPassword: '/redefinir-senha',
  bookSuite: '/reserva',
  paymentBooking: '/pagamento',
  checkIn: '/checkin',
  sessionExpired: '/sessao-expirada',
  myAccount: '/minha-conta',
  landingPage: '/area-do-proprietario',
  whoWeAre: '/quem-somos',
  privacy: '/politica-privacidade',
  terms: '/termos-uso',
  cancel: '/politicas-cancelamento',
  myReservesSubLink: '/reservas',
  confirmedBooking: '/reserva-finalizada',
  myAccountServices: '/minha-conta/sevicos',
  myAccountRequests: '/minha-conta/solicitacoes',
};

const portalPath = {
  portalHome: `${PORTAL_FRONT_URL}`,
};

export { routesPaths, portalPath };
