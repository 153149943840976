"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationErrorElement = exports.NotificationWarningElement = exports.NotificationSuccessElementInformation = exports.NotificationSuccessElementForm = void 0;
const antd_1 = require("antd");
const NotificationSuccessElementForm = () => {
    antd_1.notification.success({
        message: 'OK',
        description: 'Formulário preenchido! Entraremos em contato o mais breve possível',
    });
};
exports.NotificationSuccessElementForm = NotificationSuccessElementForm;
const NotificationSuccessElementInformation = () => {
    antd_1.notification.success({
        message: 'OK',
        description: 'Solicitação enviada! Entraremos em contato o mais breve possível',
    });
};
exports.NotificationSuccessElementInformation = NotificationSuccessElementInformation;
const NotificationWarningElement = () => {
    antd_1.notification.warning({
        message: 'Warning',
        description: 'Favor verificar se os campos estão preenchidos corretamente!',
    });
};
exports.NotificationWarningElement = NotificationWarningElement;
const NotificationErrorElement = () => {
    antd_1.notification.error({
        message: 'Error',
        description: 'Aconteceu algum erro inesperado!',
    });
};
exports.NotificationErrorElement = NotificationErrorElement;
